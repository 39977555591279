<template>
    <div id="addExam">
        <div class="questionList">
            <questionList :flag="!flag" @checkedItem="batchSend" :req="reqFlag" @internalReq="recoverReq"
                :clearReq="clearReq" @internalClearReq="recoverClearReq"></questionList>
        </div>
        <div class="title">试卷列表</div>
        <div class="paperList">
            <paperList @selectedItem="batchChange" :flag="flag" :req="examReqFlag" @internalReq="recoverExamReq"></paperList>
        </div>
        <div class="operation">
            <div class="batch">
                <span class="switch">
                    <el-switch v-model="flag" active-text="多选" inactive-text="单选"></el-switch>
                </span>
                <span class="delButton">
                    <el-button :disabled="batchFlag" type="primary" round @click="batchDel()">删除</el-button>
                </span>
            </div>
        </div>
        <div class="alterButton">
            <el-button type="success" plain @click="alter(0)">添加题目</el-button>
            <el-button type="success" plain @click="alter(1)">添加试卷</el-button>
        </div>
        <addQuestionForm @reqFlag="changeReqFlag" v-if="formFlag == 0"></addQuestionForm>
        <addExamForm @examReq="examReq" :selected="selected" @clearSelected="clearSelected" v-if="formFlag == 1">
        </addExamForm>
    </div>
</template>

<script>
import questionList from "@/components/questionList.vue"
import paperList from "@/components/paperList.vue"
import addQuestionForm from "@/components/addQuestionForm.vue"
import addExamForm from "@/components/addExamForm.vue"
import axios from "axios"

export default {
    data() {
        return {
            selected: [],
            changed: [],
            flag: false,
            batchFlag: true,
            form: {
                name: ''
            },
            reqFlag: 0,
            examReqFlag: 0,
            formFlag: null,
            clearReq: false
        }
    },
    components: {
        questionList,
        paperList,
        addQuestionForm,
        addExamForm
    },
    methods: {
        batchSend(selected) {
            this.selected = selected
            if (this.changed.length != 0 || this.selected.length != 0) {
                this.batchFlag = false
            } else {
                this.batchFlag = true
            }
        },
        batchChange(selected) {
            this.changed = selected
            if (this.changed.length != 0 || this.selected.length != 0) {
                this.batchFlag = false
            } else {
                this.batchFlag = true
            }
        },
        alter(e) {
            this.formFlag = e
        },
        changeReqFlag(req) {
            this.reqFlag = req
        },
        examReq(examReqFlag) {
            this.examReqFlag = examReqFlag
        },
        batchDel() {
            if (this.selected.length) {
                axios({
                    method: 'POST',
                    url: 'http://127.0.0.1:3000/question/del',
                    data: this.selected
                }).then(() => {
                    this.reqFlag = 1
                })
            }
            if (this.changed.length) {
                console.log(this.changed)
            }
        },
        recoverReq(req) {
            this.reqFlag = req
        },
        clearSelected(clearReq) {
            this.clearReq = clearReq
        },
        recoverClearReq(clearReq) {
            this.clearReq = clearReq
        },
        recoverExamReq(examReq) {
            this.examReqFlag = examReq
        }
    }
}
</script>

<style lang="less">
#addExam {
    .title {
        margin: 10px auto;
        font-size: 24px;
    }

    .operation {
        .batch {
            margin-top: 20px;

            .switch {
                display: inline-block;
                margin-right: 20px;
            }
        }
    }

    .alterButton {
        width: 100%;
        display: inline-block;
        margin-left: 0;
        margin-bottom: 20px;

        .el-button {
            display: inline-block;
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 20px;
            width: 30%;
        }
    }
}
</style>