<template>
  <div id="examPage">
    <examList></examList>
  </div>
</template>

<script>
import examList from "@/components/examList.vue";

export default {
  components: {
    examList
  }
}
</script>

<style lang="less">
#examPage {

  .el-header,
  .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 60px;
  }

  .el-main {
    background-color: #E9EEF3;
    color: #333;
    height: calc(100vh - 60px);
    padding: 0;
  }
}
</style>