<template>
    <div id="select">
        <el-select v-model="value" :placeholder="placeholder">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    data() {
        return {
            value: ''
        }
    },
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: []
        },

    },
    watch: {
        value(newValue) {
            this.$emit('value', newValue);
        }
    },
}
</script>