<template>
    <div id="questionList">
        <el-table :data="table" :stripe="stripe" style="width: 100%" height="300">
            <el-table-column width="60">
                <template slot-scope="scope">
                    <el-checkbox :disabled="flag" v-model="table[scope.$index].checked"
                        @change="checkBox(scope.$index, scope.row)"></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column type="index" label="序号" width="80">
            </el-table-column>
            <el-table-column prop="question_stem" label="题干" width="300">
            </el-table-column>
            <el-table-column prop="option_a" label="选项A">
            </el-table-column>
            <el-table-column prop="option_b" label="选项B">
            </el-table-column>
            <el-table-column prop="option_c" label="选项C">
            </el-table-column>
            <el-table-column prop="option_d" label="选项D">
            </el-table-column>
            <el-table-column prop="answer" label="答案">
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="danger" @click="del(scope.$index, scope.row)" icon="el-icon-delete" circle></el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import axios from "axios"

export default {
    data() {
        return {
            stripe: true,
            table: [],
            internalReq: 0,
            selected: []
        }
    },
    props: {
        req: {
            type: Number,
            default: 0
        },
        flag: {
            type: Boolean,
            default: true
        },
        clearReq: {
            type: Boolean,
            default: false
        }
    },
    created() {
        axios({
            method: 'GET',
            url: 'http://127.0.0.1:3000/question/list'
        }).then(res => {
            let temp = []
            for (let i = 0; i < res.data.length; i++) {
                let question = res.data[i]
                if (question.question_type) {
                    if (question.answer == 0) {
                        question.answer = 'A'
                    } else if (question.answer == 1) {
                        question.answer = 'B'
                    } else if (question.answer == 2) {
                        question.answer = 'C'
                    } else {
                        question.answer = 'D'
                    }
                } else {
                    if (question.answer) {
                        question.answer = 'F'
                    } else {
                        question.answer = 'T'
                    }
                }
                question.checked = false
                temp.push(question)
            }
            this.table = temp
        })
    },
    watch: {
        req(newValue, oldValue) {
            this.internalReq = newValue
            if (this.internalReq) {
                axios({
                    method: 'GET',
                    url: 'http://127.0.0.1:3000/question/list'
                }).then(res => {
                    let temp = [];
                    for (let i = 0; i < res.data.length; i++) {
                        let question = res.data[i]
                        if (question.question_type) {
                            if (question.answer == 0) {
                                question.answer = 'A'
                            } else if (question.answer == 1) {
                                question.answer = 'B'
                            } else if (question.answer == 2) {
                                question.answer = 'C'
                            } else {
                                question.answer = 'D'
                            }
                        } else {
                            if (question.answer) {
                                question.answer = 'F'
                            } else {
                                question.answer = 'T'
                            }
                        }
                        question.checked = false
                        temp.push(question)
                    }
                    this.table = temp;
                })
                this.internalReq = 0
            }
            this.$emit("internalReq", this.internalReq)
        },
        clearReq(newValue, oldValue) {
            if (newValue) {
                for (let i = 0; i < this.table.length; i++) {
                    this.table[i].checked = false
                }
                this.$emit("internalClearReq", false)
            }
        }
    },
    methods: {
        del(index, row) {
            let temp = [row.id]
            this.$confirm('删除该题目, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            }).then(() => {
                axios({
                    method: 'POST',
                    url: "http://127.0.0.1:3000/question/del",
                    data: temp
                }).then(() => {
                    axios({
                        method: 'GET',
                        url: 'http://127.0.0.1:3000/question/list'
                    }).then(res => {
                        let temp = []
                        this.count = 1
                        for (let i = 0; i < res.data.length; i++) {
                            let question = res.data[i]
                            if (question.question_type) {
                                if (question.answer == 0) {
                                    question.answer = 'A'
                                } else if (question.answer == 1) {
                                    question.answer = 'B'
                                } else if (question.answer == 2) {
                                    question.answer = 'C'
                                } else {
                                    question.answer = 'D'
                                }
                            } else {
                                if (question.answer) {
                                    question.answer = 'F'
                                } else {
                                    question.answer = 'T'
                                }
                            }
                            question.checked = false
                            temp.push(question)
                        }
                        this.table = temp
                    })
                })
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        checkBox(index, row) {
            if (row.checked) {
                this.selected[index] = row.id
            } else if (this.selected.length > 0) {
                let del = row.id
                this.selected = this.selected.filter(item => item !== del)
            }
            this.$emit('checkedItem', this.selected)
        }
    }
}
</script>

<style lang="less"></style>