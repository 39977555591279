<template>
    <div id="addExamForm">
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="试卷名称">
                <el-input v-model="form.name" placeholder="请输入试卷名称"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="newExam">立即创建</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            form: {
                name: ''
            }
        }
    },
    methods: {
        async newExam() {
            if (this.form.name == '') {
                this.$alert('试卷名不可以为空', {
                    confirmButtonText: '确定'
                })
            } else if (this.selected.length) {
                let filteredSelected = this.selected
                filteredSelected = filteredSelected.filter(element => element !== null)
                let tempData = {
                    name: this.form.name,
                    arr: filteredSelected
                }
                let result = await new Promise(async (resolve, reject) => {
                    return await axios({
                        method: 'POST',
                        url: 'http://127.0.0.1:3000/exam/new',
                        data: tempData
                    }).then(res => {
                        resolve(res)
                    })
                })
                this.$emit("examReq", 1)
                this.form = {
                    name: ''
                }
                this.$emit("clearSelected", true)

            } else {
                this.$alert('请选择题目', {
                    confirmButtonText: '确定'
                })
            }
        },
    },
    props: {
        selected: {
            type: Array,
            default: []
        }
    },
}
</script>

<style lang="less">
#addExamForm {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 20px;
    background-color: #f6f8fa;



    .el-form-item {
        margin-bottom: 20px;

        .el-input,
        .el-radio-group {
            width: 100%;
        }
    }

    .el-button {
        width: 100%;
    }

    .error-message {
        color: red;
    }
}
</style>