<template>
    <div id="paperList">
        <el-table :data="tableData" stripe style="width: 100%" height="300">
            <el-table-column width="60">
                <template slot-scope="scope">
                    <el-checkbox :disabled="!flag" v-model="tableData[scope.$index].checked"
                        @change="checkBox(scope.$index, scope.row)"></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column type="index" label="序号" width="100">
            </el-table-column>
            <el-table-column prop="name" label="试卷名" width="400">
            </el-table-column>
            <el-table-column prop="num" label="题数" width="100">
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" size="small">查看试卷</el-button>
                    <el-button type="danger" @click="del(scope.$index, scope.row)" icon="el-icon-delete"
                        circle></el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="试卷详情" :visible.sync="dialogTableVisible" width="70%">
            <el-table :data="gridData">
                <el-table-column type="index" label="序号" width="60">
                </el-table-column>
                <el-table-column prop="question_stem" label="题干" width="200">
                </el-table-column>
                <el-table-column prop="option_a" label="选项A">
                </el-table-column>
                <el-table-column prop="option_b" label="选项B">
                </el-table-column>
                <el-table-column prop="option_c" label="选项C">
                </el-table-column>
                <el-table-column prop="option_d" label="选项D">
                </el-table-column>
                <el-table-column prop="answer" label="答案">
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'

export default {
    data() {
        return {
            tableData: [],
            selected: [],
            dialogTableVisible: false,
            gridData: []
        }
    },
    created() {
        axios({
            method: 'GET',
            url: 'http://127.0.0.1:3000/exam/list'
        }).then(res => {
            let temp = {
                num: '',
                name: '',
                checked: false
            }
            let tempArr = []
            for (let i = 0; i < res.data.length; i++) {
                temp.name = res.data[i].Tables_in_new_schema
                temp.num = res.data[i].num
                tempArr.push(temp)
                temp = {
                    num: '',
                    name: '',
                    checked: false
                }
            }
            this.tableData = tempArr
        })
    },
    props: {
        flag: {
            type: Boolean,
            default: true
        },
        req: {
            type: Number,
            default: 0
        }
    },
    methods: {
        del(index, row) {
            let data = {
                name: row.name
            }
            axios({
                method: 'POST',
                url: 'http://127.0.0.1:3000/exam/del',
                data: data
            }).then(async (res) => {
                this.$alert(`${res.data.msg}`, {
                    confirmButtonText: '确定'
                })
                axios({
                    method: 'GET',
                    url: 'http://127.0.0.1:3000/exam/list'
                }).then(res => {
                    let temp = {
                        num: '',
                        name: '',
                        checked: false
                    }
                    let tempArr = []
                    for (let i = 0; i < res.data.length; i++) {
                        temp.name = res.data[i].Tables_in_new_schema
                        temp.num = res.data[i].num
                        tempArr.push(temp)
                        temp = {
                            num: '',
                            name: '',
                            checked: false
                        }
                    }
                    this.tableData = tempArr
                })
            })
        },
        checkBox(index, row) {
            if (row.checked) {
                this.selected[index] = index + 1
            } else if (this.selected.length) {
                let del = index + 1
                this.selected = this.selected.filter(item => item !== del)
            }
            this.$emit('selectedItem', this.selected)
        },
        handleClick(row) {
            let postData = {
                paperName: row.name
            }
            axios({
                method: 'POST',
                url: 'http://127.0.0.1:3000/question/paper',
                data: qs.stringify(postData)
            }).then(res => {
                let temp = []
                for (let i = 0; i < res.data.length; i++) {
                    let question = res.data[i]
                    if (question.question_type) {
                        if (question.answer == 0) {
                            question.answer = 'A'
                        } else if (question.answer == 1) {
                            question.answer = 'B'
                        } else if (question.answer == 2) {
                            question.answer = 'C'
                        } else {
                            question.answer = 'D'
                        }
                    } else {
                        if (question.answer) {
                            question.answer = 'F'
                        } else {
                            question.answer = 'T'
                        }
                    }
                    question.checked = false
                    temp.push(question)
                }
                this.gridData = temp
                this.dialogTableVisible = true
            })
        }
    },
    watch: {
        req(newValue, oldValue) {
            if (newValue) {
                axios({
                    method: 'GET',
                    url: 'http://127.0.0.1:3000/exam/list'
                }).then(res => {
                    let temp = {
                        num: '',
                        name: '',
                        checked: false
                    }
                    let tempArr = []
                    for (let i = 0; i < res.data.length; i++) {
                        temp.name = res.data[i].Tables_in_new_schema
                        temp.num = res.data[i].num
                        tempArr.push(temp)
                        temp = {
                            num: '',
                            name: '',
                            checked: false
                        }
                    }
                    this.tableData = tempArr
                    this.$emit("internalReq", 0)
                })
            }
        }
    }
}
</script>

<style lang="less"></style>