<template>
    <div id="database">
        <div class="selections">
            <el-row>
                <el-col :span="7">
                    <div class="grid-content">
                        <selection placeholder="图表类型" :options="[
                    { value: 'line', label: '折线图' },
                    { value: 'bar', label: '柱状图' },
                    { value: 'pie', label: '饼图' },
                    { value: 'scatter', label: '散点图' },
                    { value: 'tree', label: '树图' }
                ]" @value="selectChart"></selection>
                    </div>
                </el-col>
                <el-col :span="7">
                    <div class="grid-content"
                        v-if="chartOpt.series[0].type !== 'pie' && chartOpt.series[0].type !== null">
                        <selection placeholder="x轴类型" :options="[
                    { value: 'value', label: '数值轴' },
                    { value: 'category', label: '类目轴' },
                    { value: 'time', label: '时间轴' },
                    { value: 'log', label: '对数轴' }
                ]" @value="selectxAxis">
                        </selection>
                        <textarea v-model="xAxisData" placeholder="输入 X 轴数据，每行一个"></textarea>
                    </div>
                </el-col>
                <el-col :span="7">
                    <div class="grid-content"
                        v-if="chartOpt.series[0].type !== 'pie' && chartOpt.series[0].type !== null">
                        <selection placeholder="y轴类型" :options="[
                    { value: 'value', label: '数值轴' },
                    { value: 'category', label: '类目轴' },
                    { value: 'time', label: '时间轴' },
                    { value: 'log', label: '对数轴' }
                ]" @value="selectyAxis">
                        </selection>
                        <textarea v-model="yAxisData" placeholder="输入 Y 轴数据，每行一个"></textarea>
                    </div>
                </el-col>
                <el-col :span="3">
                    <div class="grid-content"
                        v-if="chartOpt.series[0].type !== 'pie' && chartOpt.series[0].type !== null">
                        <el-button type="primary" icon="el-icon-check" circle @click="addChart()"></el-button>
                    </div>
                </el-col>
            </el-row>
        </div>
        <chart :chartOpt="chartOpt"></chart>
    </div>
</template>

<script>
import chart from "@/components/echarts/chart.vue";
import selection from "@/components/selection.vue";

export default {
    components: {
        chart,
        selection
    },
    data() {
        return {
            chartOpt: {
                series: [
                    {
                        type: null
                    }
                ],
                xAxis: {
                    type: null
                },
                yAxis: {
                    type: null
                }
            },
            xAxisData: '',
            yAxisData: ''
        }
    },
    methods: {
        addChart() {
            const xAxisLines = this.xAxisData.split('\n');
            const yAxisLines = this.yAxisData.split('\n');

            if (xAxisLines.length !== yAxisLines.length) {
                alert('X 轴数据与 Y 轴数据长度不一致！');
                return;
            }

            const chartData = xAxisLines.map((x, index) => {
                const y = parseFloat(yAxisLines[index]);
                return [x, y];
            });

            this.$set(this.chartOpt.xAxis, 'data', xAxisLines)
            this.$set(this.chartOpt.series[0], 'data', chartData)
        },
        selectChart(type) {
            this.chartOpt.series[0].type = type
        },
        selectxAxis(type) {
            this.chartOpt.xAxis.type = type
        },
        selectyAxis(type) {
            this.chartOpt.yAxis.type = type
        }
    }
}
</script>

<style lang="less">
#database {
    .selections {
        margin: 40px auto;

        .el-row {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .el-col {
            border-radius: 4px;
        }

        .grid-content {
            border-radius: 4px;
            min-height: 36px;
        }
    }

}
</style>