<template>
    <div id="navMenu">
        <el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" @select="changeTitle" :router="true">
            <el-menu-item index="examPage">
                <i class="el-icon-setting"></i>
                <span slot="title">考试</span>
            </el-menu-item>
            <el-menu-item index="accountList">
                <i class="el-icon-setting"></i>
                <span slot="title">账号列表</span>
            </el-menu-item>
            <el-menu-item index="addExam">
                <i class="el-icon-setting"></i>
                <span slot="title">试题管理</span>
            </el-menu-item>
            <el-menu-item index="database">
                <i class="el-icon-setting"></i>
                <span slot="title">题库数据</span>
            </el-menu-item>
        </el-menu>
    </div>
</template>

<script>
export default {
    data() {
        return { 

        }
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        changeTitle(index) {
            if (index == 'examPage') {
                index = '考试'
            }
            if (index == 'accountList') {
                index = '账号列表'
            }
            if (index == 'addExam') {
                index = '新建试卷'
            }
            this.$emit('changeTitle', index)
        }
    }
}
</script>

<style lang="less">
#navMenu {
    .el-col {
        width: 100%;
    }
}
</style>