<template>
    <div id="login">
        <el-container>
            <el-header>
                <topBar>
                    <template v-slot:left>
                        <div class="goBack">
                            <i class="el-icon-arrow-left" @click="goBack()"></i>
                        </div>
                    </template>
                    <template v-slot:middle>
                        <span>登录注册</span>
                    </template>
                </topBar>
            </el-header>
            <el-main>
                <el-form :model="form" ref="form" label-width="100px">
                    <el-form-item label="账号">
                        <el-input v-model="form.username"></el-input>
                    </el-form-item>
                    <el-form-item label="密码">
                        <el-input type="password" v-model="form.password"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">提交</el-button>
                    </el-form-item>
                </el-form>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import topBar from "../components/topBar.vue";

export default {
    components: {
        topBar
    },
    data() {
        return {
            form: {
                username: '',
                password: '',
                token: ''
            }
        }
    },
    mounted() {
        let flag = this.$store.getters.isLogin
        let temp = localStorage.getItem("token")
        if (temp == undefined) {
            localStorage.setItem("token", 0)
        }
        localStorage.setItem("status", 0)
        localStorage.setItem("flag", flag)
    },
    methods: {
        goBack() {
            this.$router.push('/')
        },
        onSubmit() {
            if (this.form.username == '' || this.form.password == '') {
                this.$alert('账号密码不能为空', {
                    confirmButtonText: '确定'
                })
            } else {
                this.form.token = localStorage.getItem("token")
                axios({
                    method: "post",
                    url: "http://127.0.0.1:3000/account/register",
                    data: qs.stringify(this.form)
                }).then(async res => {
                    await this.$store.dispatch("userLogin", true)
                    let flag = this.$store.getters.isLogin
                    localStorage.setItem("token", res.data.token)
                    localStorage.setItem("status", res.data.status)
                    localStorage.setItem("flag", flag)
                    await this.$alert(res.data.msg, {
                        confirmButtonText: '确定'
                    })
                    this.$router.push("/");
                })
            }
        }
    }
}
</script>

<style lang="less">
#login {

    .el-header {
        background-color: #b3c0d1;
        color: #333;
        text-align: center;
        line-height: 60px;
        padding: 0;

        .goBack {
            text-align: left;
            font-size: 32px;
            margin: 0 50px;

            .el-icon-arrow-left {
                border: 3px solid #333;
                border-radius: 50%;
            }
        }
    }

    .el-main {
        background-color: #e9eef3;
        color: #333;
        text-align: center;
        line-height: 160px;
        height: calc(100vh - 60px);

        .el-form-item {
            margin-bottom: 15px;
        }

        .el-button {
            width: 100%;
            background-color: #007bff;
            color: #fff;
            border: none;
            border-radius: 5px;

            &:hover {
                background-color: #0056b3;
            }
        }
    }

    .el-footer {
        background-color: #b3c0d1;
        color: #333;
        text-align: center;
        line-height: 60px;
        padding: 0;
    }
}
</style>