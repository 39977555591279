<template>
  <div id="home">
    <el-container>
      <el-header>
        <topBar>
          <template v-slot:middle>
            <span>{{ title }}</span>
          </template>
          <template v-slot:right>
            <loginRegister></loginRegister>
          </template>
        </topBar>
      </el-header>
      <el-container>
        <el-aside width="200px">
          <navMenu @changeTitle="changeTitle"></navMenu>
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import navMenu from "@/components/navMenu.vue"
import topBar from "@/components/topBar.vue"
import loginRegister from "@/components/loginRegister.vue";

export default {
  data() {
    return {
      title: ''
    }
  },
  components: {
    navMenu,
    topBar,
    loginRegister
  },
  methods: {
    changeTitle(title) {
      this.title = title
    }
  }
}
</script>

<style lang="less">
#home {

  .el-header,
  .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 60px;
    padding: 0;
  }

  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 200px;
    height: calc(100vh - 60px);
  }

  .el-main {
    background-color: #fff;
    color: #333;
    text-align: center;
    height: calc(100vh - 60px);
    padding: 0;
  }
}
</style>