<template>
    <div id="loginRegister">
        <el-button type="primary" round @click="login()">{{ this.title }}</el-button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: ''
        }
    },
    created() {
        if (this.$store.getters.isLogin == false) {
            this.title = '登录注册'
        } else {
            this.title = '退出登录'
        }
    },
    methods: {
        login() {
            if (this.$store.getters.isLogin == false) {
                this.title = '登录注册'
                this.$router.push("/login")
            } else {
                this.title = '退出登录'
                this.$confirm('确认退出登录吗？', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    this.$message({
                        type: 'success',
                        message: '退出成功!'
                    })
                    this.$nextTick(() => {
                        this.title = '登录注册'
                    })
                    await this.$store.dispatch("userLogin", false)
                    let flag = this.$store.getters.isLogin
                    localStorage.setItem("flag", flag)
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消退出'
                    })
                })
            }
        }
    }
}
</script>

<style lang="less"></style>