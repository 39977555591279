<template>
    <div id="chart">
        <div ref="echarts" style="width: 100%; height: 400px;"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
    data() {
        return {
            chart: null
        }
    },
    methods: {
        createEchart() {
            this.chart = echarts.init(this.$refs.echarts)
            this.chart.setOption(this.chartOpt)
        }
    },
    props: {
        chartOpt: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        chartOpt: {
            handler(newVal, oldVal) {
                if (this.chartOpt.series[0].type) {
                    if (this.chartOpt.series[0].type == 'pie') {
                        delete this.chartOpt.xAxis
                        delete this.chartOpt.yAxis
                        this.createEchart()
                    } else if (this.chartOpt.xAxis.type && this.chartOpt.yAxis.type) {
                        this.createEchart()
                    }
                }
            },
            deep: true
        }
    }
}
</script>