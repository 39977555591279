<template>
    <div id="addQuestionForm">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="题干">
                <el-input v-model="form.questionStem"></el-input>
            </el-form-item>
            <el-form-item label="选项A" v-if="form.questionType == '单选题'">
                <el-input v-model="form.A"></el-input>
            </el-form-item>
            <el-form-item label="选项B" v-if="form.questionType == '单选题'">
                <el-input v-model="form.B"></el-input>
            </el-form-item>
            <el-form-item label="选项C" v-if="form.questionType == '单选题'">
                <el-input v-model="form.C"></el-input>
            </el-form-item>
            <el-form-item label="选项D" v-if="form.questionType == '单选题'">
                <el-input v-model="form.D"></el-input>
            </el-form-item>
            <el-form-item label="答案">
                <el-radio-group v-if="form.questionType == '判断题'" v-model="form.answer">
                    <el-radio label="T"></el-radio>
                    <el-radio label="F"></el-radio>
                </el-radio-group>
                <span v-if="form.questionType == null">{{ '请先选择题型' }}</span>
                <el-radio-group v-if="form.questionType == '单选题'" v-model="form.answer">
                    <el-radio label="A"></el-radio>
                    <el-radio label="B"></el-radio>
                    <el-radio label="C"></el-radio>
                    <el-radio label="D"></el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="题型">
                <el-radio-group v-model="form.questionType">
                    <el-radio label="判断题"></el-radio>
                    <el-radio label="单选题"></el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'

export default {
    data() {
        return {
            form: {
                questionStem: '',
                A: '',
                B: '',
                C: '',
                D: '',
                answer: '',
                questionType: null
            },
            flag: false,
            batch: [],
            batchFlag: true,
            reqFlag: 0
        }
    },
    methods: {
        onSubmit() {
            if (this.form.questionType == null || this.form.questionStem == "" || this.form.answer == "") {
                this.$alert('输入不可以为空', {
                    confirmButtonText: '确定'
                })
            } else {
                if (this.form.questionType == "单选题") {
                    if (this.form.A == "" || this.form.B == "" || this.form.C == "" || this.form.D == "") {
                        this.$alert('输入不可以为空', {
                            confirmButtonText: '确定'
                        })
                    } else {
                        axios({
                            method: 'POST',
                            data: qs.stringify(this.form),
                            url: "http://127.0.0.1:3000/question/add"
                        }).then(() => {
                            this.reqFlag = 1
                            this.$emit("reqFlag", this.reqFlag)
                            this.form = {
                                questionStem: '',
                                A: '',
                                B: '',
                                C: '',
                                D: '',
                                answer: '',
                                questionType: null
                            }
                        })
                    }
                } else {
                    axios({
                        method: 'POST',
                        data: qs.stringify(this.form),
                        url: "http://127.0.0.1:3000/question/add"
                    }).then(() => {
                        this.reqFlag = 1
                        this.$emit("reqFlag", this.reqFlag)
                        this.form = {
                            questionStem: '',
                            A: '',
                            B: '',
                            C: '',
                            D: '',
                            answer: '',
                            questionType: null
                        }
                    })
                }
            }
        }
    }
}
</script>

<style lang="less">
#addQuestionForm {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 20px;
    background-color: #f6f8fa;

    .el-form-item {
        margin-bottom: 20px;

        .el-input,
        .el-radio-group {
            width: 100%;
        }
    }

    .el-button {
        width: 100%;
    }

    .error-message {
        color: red;
    }
}
</style>