import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import loginPage from "../views/loginPage.vue"
import examPage from "../views/examPage.vue"
import accountList from "../views/accountList.vue"
import addExam from "../views/addExam.vue"
import database from "../views/database";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: 'examPage',
        name: 'examPage',
        component: examPage
      },
      {
        path: 'accountList',
        name: 'accountList',
        component: accountList
      },
      {
        path: 'addExam',
        name: 'addExam',
        component: addExam
      },
      {
        path: 'database',
        name: 'database',
        component: database
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: loginPage
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.name == "home") {
//     next()
//   } else if (to.name != "login" && !this.$store.getters.isLogin) {
//     next("/account/register")
//   } else {
//     next()
//   }
// })

export default router
