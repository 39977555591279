var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"database"}},[_c('div',{staticClass:"selections"},[_c('el-row',[_c('el-col',{attrs:{"span":7}},[_c('div',{staticClass:"grid-content"},[_c('selection',{attrs:{"placeholder":"图表类型","options":[
                { value: 'line', label: '折线图' },
                { value: 'bar', label: '柱状图' },
                { value: 'pie', label: '饼图' },
                { value: 'scatter', label: '散点图' },
                { value: 'tree', label: '树图' }
            ]},on:{"value":_vm.selectChart}})],1)]),_c('el-col',{attrs:{"span":7}},[(_vm.chartOpt.series[0].type !== 'pie' && _vm.chartOpt.series[0].type !== null)?_c('div',{staticClass:"grid-content"},[_c('selection',{attrs:{"placeholder":"x轴类型","options":[
                { value: 'value', label: '数值轴' },
                { value: 'category', label: '类目轴' },
                { value: 'time', label: '时间轴' },
                { value: 'log', label: '对数轴' }
            ]},on:{"value":_vm.selectxAxis}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.xAxisData),expression:"xAxisData"}],attrs:{"placeholder":"输入 X 轴数据，每行一个"},domProps:{"value":(_vm.xAxisData)},on:{"input":function($event){if($event.target.composing)return;_vm.xAxisData=$event.target.value}}})],1):_vm._e()]),_c('el-col',{attrs:{"span":7}},[(_vm.chartOpt.series[0].type !== 'pie' && _vm.chartOpt.series[0].type !== null)?_c('div',{staticClass:"grid-content"},[_c('selection',{attrs:{"placeholder":"y轴类型","options":[
                { value: 'value', label: '数值轴' },
                { value: 'category', label: '类目轴' },
                { value: 'time', label: '时间轴' },
                { value: 'log', label: '对数轴' }
            ]},on:{"value":_vm.selectyAxis}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.yAxisData),expression:"yAxisData"}],attrs:{"placeholder":"输入 Y 轴数据，每行一个"},domProps:{"value":(_vm.yAxisData)},on:{"input":function($event){if($event.target.composing)return;_vm.yAxisData=$event.target.value}}})],1):_vm._e()]),_c('el-col',{attrs:{"span":3}},[(_vm.chartOpt.series[0].type !== 'pie' && _vm.chartOpt.series[0].type !== null)?_c('div',{staticClass:"grid-content"},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-check","circle":""},on:{"click":function($event){return _vm.addChart()}}})],1):_vm._e()])],1)],1),_c('chart',{attrs:{"chartOpt":_vm.chartOpt}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }