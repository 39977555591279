<template>
    <div id="accountList">
        <el-table :data="table" :stripe="stripe" style="width: 100%" height="300">
            <el-table-column width="60">
                <template slot-scope="scope">
                    <el-checkbox :disabled="!flag" v-model="table[scope.row.count - 1].checked"
                        @change="checkBox(scope.$index, scope.row)"></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column prop="count" label="序号" width="80">
            </el-table-column>
            <el-table-column prop="username" label="用户名" width="400">
            </el-table-column>
            <el-table-column prop="id" label="用户id" width="100">
            </el-table-column>
            <el-table-column prop="auth" label="用户权限" width="200">
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="danger" @click="del(scope.$index, scope.row)" icon="el-icon-delete" circle></el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="batch">
            <span class="switch">
                <el-switch v-model="flag" active-text="多选" inactive-text="单选"></el-switch>
            </span>
            <span class="button">
                <el-button :disabled="batchFlag" type="primary" round @click="batchDel()">删除</el-button>
            </span>
        </div>
        <div class="submitForm">
            <el-form :model="form" ref="form" label-width="100px">
                <el-form-item label="用户名">
                    <el-input v-model="form.username"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input v-model="form.password" type="password"></el-input>
                </el-form-item>
                <el-form-item label="用户权限">
                    <el-radio-group v-model="form.userAuth">
                        <el-radio label="教师"></el-radio>
                        <el-radio label="学生"></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">提交</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import qs from "qs"

export default {
    data() {
        return {
            stripe: true,
            count: 1,
            table: [],
            form: {
                username: '',
                password: '',
                userAuth: ''
            },
            flag: false,
            batchFlag: true,
            selected: []
        }
    },
    created() {
        axios({
            method: 'GET',
            url: 'http://127.0.0.1:3000/account/list'
        }).then(res => {
            let temp = []
            this.count = 1
            for (let i = 0; i < res.data.length; i++) {
                const account = res.data[i]
                account.count = this.count
                temp.push(account)
                this.count++
            }
            this.table = temp
        })
    },
    methods: {
        del(index, row) {
            let temp = [row.id]
            this.$confirm('删除该账号, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            }).then(() => {
                axios({
                    method: 'POST',
                    url: "http://127.0.0.1:3000/account/del",
                    data: temp
                }).then(() => {
                    axios({
                        method: 'GET',
                        url: 'http://127.0.0.1:3000/account/list'
                    }).then(res => {
                        let temp = []
                        this.count = 1
                        for (let i = 0; i < res.data.length; i++) {
                            const account = res.data[i]
                            account.count = this.count
                            temp.push(account)
                            this.count++
                        }
                        this.table = temp
                    })
                })
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })
            })
        },
        onSubmit() {
            if (this.form.userAuth == '' || this.form.username == '' || this.form.password == '""') {
                this.$alert('输入不可以为空', {
                    confirmButtonText: '确定'
                })
            } else {
                axios({
                    method: 'POST',
                    data: qs.stringify(this.form),
                    url: "http://127.0.0.1:3000/account/add"
                }).then(() => {
                    this.form = {
                        username: '',
                        password: '',
                        userAuth: ''
                    }
                    axios({
                        method: 'GET',
                        url: 'http://127.0.0.1:3000/account/list'
                    }).then(res => {
                        let temp = []
                        this.count = 1
                        for (let i = 0; i < res.data.length; i++) {
                            const account = res.data[i]
                            account.count = this.count
                            temp.push(account)
                            this.count++
                        }
                        this.table = temp
                    })
                })
            }
        },
        checkBox(index, row) {
            if (row.checked) {
                this.selected[index] = row.id
            } else if (this.selected.length) {
                let del = row.id
                this.selected = this.selected.filter(item => item !== del)
            }
            if (this.selected.length) {
                this.batchFlag = false
            } else {
                this.batchFlag = true
            }
        },
        batchDel() {
            if (this.selected.length) {
                axios({
                    method: 'post',
                    url: 'http://127.0.0.1:3000/account/del',
                    data: this.selected
                }).then(() => {
                    this.selected = []
                    axios({
                        method: 'get',
                        url: 'http://127.0.0.1:3000/account/list'
                    }).then(res => {
                        let temp = []
                        this.count = 1
                        for (let i = 0; i < res.data.length; i++) {
                            const account = res.data[i]
                            account.count = this.count
                            temp.push(account)
                            this.count++
                        }
                        this.table = temp
                    })
                })
            } else {
                this.$alert('未选择任何数据', {
                    confirmButtonText: '确定'
                })
            }
        }
    }
}
</script>

<style lang="less">
#accountList {
    align-items: center;

    .batch {
        margin-top: 20px;

        .switch {
            display: inline-block;
            margin-right: 20px;
        }
    }

    .submitForm {
        max-width: 400px;
        margin: 0 auto;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 20px;
        background-color: #f6f8fa;

        .el-form-item {
            margin-bottom: 20px;

            .el-input,
            .el-radio-group {
                width: 100%;
            }
        }

        .el-button {
            width: 100%;
        }

        .error-message {
            color: red;
        }
    }

}
</style>